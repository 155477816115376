import { useState } from "react";

import { Modal, Button } from "react-bootstrap";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";
import Base64Downloader from "common-base64-downloader-react";

import "../styles/modalPdf.css";
import GetServicioByIdEvidenciaRequisito from "../selectors/GetServicioByIdEvidenciaRequisito";
import { PutEvidenciaVerificar } from "../selectors/PutEvidenciaVerificar";

export const ModalPdf = (props) => {
  const { idEvidencia, token, onHide, actualizar } = props;
  const { requisitosValidar } = GetServicioByIdEvidenciaRequisito({
    token,
    idEvidencia,
  });
  const { idEvidenciaRequisito, content64 } = requisitosValidar;
  const [evidencia, setEvidencia] = useState({
    idEvidenciaRequisito: "",
    estadoEvidencia: "Estado evidencia",
    estadoDetalle: "",
    content64: "",
  });
  const [numPages, setNumPages] = useState(null);
  const [text, setText] = useState("");

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleEstado = (estado) => {
    estado === "Valido" && setText("");
    setEvidencia({
      idEvidenciaRequisito: idEvidenciaRequisito,
      estadoEvidencia: estado,
      estadoDetalle: text,
      content64: content64,
    });
  };

  const handleClose = () => {
    PutEvidenciaVerificar(token, evidencia);
    onHide();
    setTimeout(() => {
      actualizar();
    }, 1500);
  };
  console.log(evidencia);
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header className="modal-header">
          <Modal.Title id="contained-modal-title-vcenter ">
            Documento a validar
          </Modal.Title>
          <Button onClick={() => handleClose()}>Cerrar</Button>
        </Modal.Header>
        <div className="sticky-content">
          <div className="button-modal d-flex justify-content-around m-3">
            <Button
              className="btn btn-success w-25 button-line"
              onClick={() => handleEstado("Valido")}
            >
              Aceptar
            </Button>
            <div
              className={
                evidencia.estadoEvidencia === "Estado evidencia"
                  ? "estado-evidencia w-25 bg-light rounded d-flex justify-content-center align-items-center m-0 border border-white"
                  : evidencia.estadoEvidencia === "Valido"
                  ? "estado-evidencia w-25 rounded d-flex justify-content-center align-items-center m-0 bg-success border border-white"
                  : "estado-evidencia w-25 rounded d-flex justify-content-center align-items-center m-0 bg-danger border border-white"
              }
            >
              <div
                className={
                  evidencia.estadoEvidencia === "Estado evidencia"
                    ? "estado-evidencia-text d-flex justify-content-center align-items-center m-0"
                    : evidencia.estadoEvidencia === "Valido"
                    ? "estado-evidencia-text m-0 bg-success "
                    : "estado-evidencia-text m-0 bg-danger "
                }
              >
                <p
                  className={
                    evidencia.estadoEvidencia === "InValido" ||
                    evidencia.estadoEvidencia === "Valido"
                      ? "text-white m-0"
                      : "text-primary m-0"
                  }
                >
                  {evidencia.estadoEvidencia === "Estado evidencia"
                    ? "Estado evidencia"
                    : evidencia.estadoEvidencia === "Valido"
                    ? "Aceptado"
                    : "Rechazado"}
                </p>
              </div>
            </div>

            <Button
              className={
                text.length < 20 || text.length > 499
                  ? "btn btn-danger w-25 button-line disabled"
                  : "btn btn-danger w-25 button-line"
              }
              onClick={() => handleEstado("InValido")}
            >
              Rechazar
            </Button>
          </div>
          <div className="text-modal mb-3 d-flex justify-content-center">
            <textarea
              className="w-75 h-100 input-text"
              placeholder="Especificar motivo rechazo (obligatorio mas de 20 caracteres y menos de 500)"
              name="text"
              value={text}
              onChange={(e) => {
                setText(e.target.value);
              }}
            />
          </div>
          <div className="button-descarga d-flex justify-content-center mb-2">
            <Base64Downloader
              className="enlace-descarga "
              base64={`data:application/pdf;base64,${content64}`}
              downloadName="download"
            >
              Descargar Documento
            </Base64Downloader>
          </div>
        </div>
        <Modal.Body>
          <Document
            file={`data:application/pdf;base64,${content64}`}
            options={{
              workerSrc: "../node_modules/pdfjs-dist/build/pdf.worker.js",
            }}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
          <p>Número de páginas {numPages}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleClose()}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
