import { AppRouters } from "./routes/AppRouters";

function Conecta() {
  return (
    <div>
      <AppRouters />
    </div>
  );
}

export default Conecta;
