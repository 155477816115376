import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "../styles/dashboard.css";

import { ItemTable } from "./ItemTable";

export const Table = ({
  setModalShow,
  setIdEvidenciaRequisito,
  setToken,
  requisitosValidar,
}) => {
  const [requisitosValidarFilter, setRequisitosValidarFilter] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchSelectText, setSearchSelectText] = useState("default");

  const navigate = useNavigate();

  const handleLogout = () => {
    setToken(null);
    navigate("/login", { replace: true });
  };

  const handleBuscar = () => {
    setRequisitosValidarFilter(
      requisitosValidar.filter(
        (requisito) =>
          requisito.nombreSujeto
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          requisito.requisito.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };

  useEffect(() => {
    const handleFilter = (searchSelectText) => {
      if (searchSelectText === "default") {
        setRequisitosValidarFilter(requisitosValidar);
      } else {
        setRequisitosValidarFilter(
          requisitosValidar.filter((requisito) =>
            requisito.sujeto
              .toLowerCase()
              .includes(searchSelectText.toLowerCase())
          )
        );
      }
    };
    handleFilter(searchSelectText);
  }, [requisitosValidar, searchSelectText]);

  useEffect(() => {
    setRequisitosValidarFilter(requisitosValidar);
  }, [requisitosValidar]);

  return (
    <div>
      <div className="dashboard animate__animated animate__fadeIn animate__slow">
        <header>
          <button className="btn btn-light m-3" onClick={handleLogout}>
            <i className="fa-solid fa-right-from-bracket"></i>
          </button>
        </header>
        <div className="container">
          <h1 className="mt-3">Requisitos por Validar</h1>
          <hr />
          <div className="d-flex justify-content-between mb-5">
            <select
              className="mt-1 rounded selector"
              name="searchText"
              value={searchSelectText}
              onChange={(e) => setSearchSelectText(e.target.value)}
            >
              <option value="default">Selecciona Sujeto</option>
              {requisitosValidar.map((requisito) => (
                <option key={requisito.sujeto} value={requisito.sujeto}>
                  {requisito.sujeto}
                </option>
              ))}
            </select>
            {/* <option value="servicio">Servicio</option>
               <option value="documentacion">Documentacion</option>
               <option value="trabajador">Trabajador</option> */}

            <div className="searchTextArea d-flex justify-content-end w-75">
              <input
                type="text"
                className="w-100 h-75 p-1 mt-1 me-2 rounded"
                placeholder="Encuentra por nombre o requisito"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <button
                className="btn btn-primary m-1 h-75 rounded"
                onClick={handleBuscar}
              >
                Buscar
              </button>
              <button
                className="btn btn-primary m-1 h-75 w-50 rounded"
                onClick={() => {
                  setRequisitosValidarFilter(requisitosValidar);
                  setSearchSelectText("default");
                  setSearchText("");
                }}
              >
                Mostrar Todos Pendientes
              </button>
            </div>
          </div>
          <div className="total border border-light bg-info rounded d-inline p-2">
            Total: {requisitosValidarFilter.length}
          </div>
          <div className="table-container">
            <table className="table table-hover mt-5">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Nombre</th>
                  <th scope="col">Requisito</th>
                  <th scope="col">Sujeto</th>
                  <th scope="col" className="text-center">
                    Fecha de Subida
                  </th>
                  <th scope="col" className="text-center">
                    Estado
                  </th>
                </tr>
              </thead>
              <ItemTable
                requisitosValidarFilter={requisitosValidarFilter}
                setIdEvidenciaRequisito={setIdEvidenciaRequisito}
                setModalShow={setModalShow}
              />
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
