import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Login } from "../components/Login";
import { Dashboard } from "../components/Dashboard";
import { PublicRoute } from "./PublicRoute";
import { PrivateRoute } from "./PrivateRoute";

export const AppRouters = () => {
  const [token, setToken] = useState(null);
  return (
    <div>
      <Routes>
        <Route
          path="/login"
          element={
            <PublicRoute token={token}>
              <Login setToken={setToken} />
            </PublicRoute>
          }
        />
        <Route
          path="/*"
          element={
            <PrivateRoute token={token}>
              <Dashboard token={token} setToken={setToken} />
            </PrivateRoute>
          }
        />
      </Routes>
    </div>
  );
};
