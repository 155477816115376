import { useState, useEffect } from "react";

import axios from "axios";

const GetRequisitosPendientes = (token = "", actualizar) => {
  const [requisitosValidar, setRequisitosValidar] = useState();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_basicURL_gescae_evidencia}pendientes-verificar`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(result);
        setRequisitosValidar(result.data);
      } catch (error) {
        console.log(error.message);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [actualizar, token]);
  return { requisitosValidar, error, loading };
};

export default GetRequisitosPendientes;
