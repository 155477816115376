import React, { useState } from "react";

import { ModalPdf } from "./ModalPdf";

import "../styles/dashboard.css";

import GetRequisitosPendientes from "../selectors/GetRequisitosPendientes";
import { Table } from "./Table";

export const Dashboard = ({ token, setToken }) => {
  const [modalShow, setModalShow] = useState(false);
  const [idEvidenciaRequisito, setIdEvidenciaRequisito] = useState("");
  const [actualizar, setActualizar] = useState(true);
  const { requisitosValidar, error, loading } = GetRequisitosPendientes(
    token,
    actualizar
  );
  return (
    <>
      {loading && (
        <div className="justify-content-center spinner">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          <span> Loading...</span>
        </div>
      )}
      {error && <div>{error}</div>}
      {requisitosValidar && (
        <Table
          setModalShow={setModalShow}
          setIdEvidenciaRequisito={setIdEvidenciaRequisito}
          setToken={setToken}
          requisitosValidar={requisitosValidar}
        />
      )}
      {modalShow && (
        <ModalPdf
          className="animate__animated animate__fadeIn"
          idEvidencia={idEvidenciaRequisito}
          token={token}
          show={modalShow}
          onHide={() => setModalShow(false)}
          actualizar={() => setActualizar(!actualizar)}
        />
      )}
    </>
  );
};
