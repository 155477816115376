import axios from "axios";

export const PutEvidenciaVerificar = (token, evidencia) => {
  axios
    .put(
      `${process.env.REACT_APP_basicURL_gescae_evidencia}verificar`,
      evidencia,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};
