import React from "react";

export const ItemTable = ({
  requisitosValidarFilter,
  setIdEvidenciaRequisito,
  setModalShow,
}) => {
  const handleVer = (e) => {
    setIdEvidenciaRequisito(e.target.id);
    setModalShow(true);
  };
  return (
    <tbody>
      {requisitosValidarFilter.map((requisito) => {
        return (
          <tr key={requisito.idEvidenciaRequisito}>
            <th scope="row">
              <button
                id={requisito.idEvidenciaRequisito}
                className="btn btn-success"
                onClick={(e) => handleVer(e)}
              >
                <i
                  className="fa-solid fa-folder-open"
                  id={requisito.idEvidenciaRequisito}
                  onClick={(e) => handleVer(e)}
                ></i>
              </button>
            </th>
            <td className="align-middle">{requisito.nombreSujeto}</td>
            <td className="align-middle">{requisito.requisito}</td>
            <td className="align-middle">{requisito.sujeto}</td>
            <td className="align-middle text-center">{requisito.timeStamp}</td>
            <td className="align-middle text-center">
              {requisito.estadoRequisito}
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};
